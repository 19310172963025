import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/GlobalFunction";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";

const initialState = {
    organizationList : null,
    projectListsByOrg : null,
    error: null,
    loading: false
}


export const listOrganization = createAsyncThunk("Organization/OrgList", async(Mydata)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Organization/index`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {key: Mydata.key}    
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  
export const OnChangeOrgProjectList = createAsyncThunk("Organization/OnChangeOrgProjectList", async(Mydata)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/User_invitation/OnChangeOrgProjectList`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {org_id: Mydata.org_id}    
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });


  export const updateOrganizationById = createAsyncThunk("Organization/updateOrganization", async(Mydata)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Organization/doUpdateOrganization/${Mydata.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: Mydata.payload    
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });


  export const createOrganization = createAsyncThunk("Organization/createOrganization", async(Mydata)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Organization/doCreateOrganization`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: Mydata    
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });


  export const archiveOrganization = createAsyncThunk("Organization/archiveOrganization", async(Mydata)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Organization/archiveOrganization/${Mydata}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},  
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

const OrganizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        removeOrg: (state,action)=>{
            console.log(JSON.parse(JSON.stringify(state.organizationList)))
            state.organizationList = state.organizationList.filter(org => org.id !== action.payload.id)
            console.log(JSON.parse(JSON.stringify(state.organizationList)))
        }
    },
    extraReducers:(builder)=>{
            builder
            //timelog list
            .addCase(listOrganization.pending,(state,action)=>{
                state.loading =  true
                state.error = true
                state.organizationList = null
            })
            .addCase(listOrganization.fulfilled,(state,action)=>{
                state.loading =  false
                state.error = false
                state.organizationList = action.payload.data
               
            })
            .addCase(listOrganization.rejected,(state,action)=>{
                state.loading =  false
                state.error = action.payload.error
                state.organizationList = null
            })
            
            builder
            //timelog list
            .addCase(OnChangeOrgProjectList.pending,(state,action)=>{
                state.error = true
                state.projectListsByOrg = null
            })
            .addCase(OnChangeOrgProjectList.fulfilled,(state,action)=>{
                state.error = true
                state.projectListsByOrg = action.payload.data
               
            })
            .addCase(OnChangeOrgProjectList.rejected,(state,action)=>{
                state.error = true
                state.projectListsByOrg = null
            })

            .addCase(createOrganization.pending,(state,action)=>{
                state.error = true
            })
            .addCase(createOrganization.fulfilled,(state,action)=>{
                state.error = action.payload.errors
            })
            .addCase(createOrganization.rejected,(state,action)=>{
                state.error = true
            })


            .addCase(updateOrganizationById.pending,(state,action)=>{
                state.error = true
            })
            .addCase(updateOrganizationById.fulfilled,(state,action)=>{
                state.error = action.payload.errors
            })
            .addCase(updateOrganizationById.rejected,(state,action)=>{
                state.error = true
            })
            
            .addCase(archiveOrganization.pending,(state,action)=>{
                state.loading =  true
                state.error = true
            })
            .addCase(archiveOrganization.fulfilled,(state,action)=>{
                state.loading =  false
                state.error = false
            })
            .addCase(archiveOrganization.rejected,(state,action)=>{
                state.loading =  false
                state.error = action.payload.error
            })
        }
    
});


export const {removeOrg} = OrganizationSlice.actions;

export default OrganizationSlice.reducer;

export const getOrganization = (state) => state.organization.organizationList;